import React from "react"
import Layout from "../components/Layout"

export default function Impressum() {
  return (
    <Layout>
      <div className="deco">
          <img
            src="/adler.jpg"
            alt=""
          />        
      </div>
      <div className="content">
        <h1>Kanzlei Rechtsanwalt Friedrich Schweikert</h1>
        <p className="no-margin">
            Telefon: 08061- 938689- 5 &nbsp;&nbsp; (Bitte lange klingeln lassen)
            <br />
            Telefax: 08061- 938689- 4
        </p>
        <p>
            Adresse: Lagerhausstr.12, 83043 Bad Aibling
            <br />
            <b>
              <u>Postanschrift:</u> Postfach 1248, in 83036 Bad Aibling
            </b>          
        </p>
        <p>
            Mail:{" "}
            <img
              src="/email_black.gif"
              alt="Emailadresse"
              style={{ verticalAlign: "middle" }}
            />          
        </p>
        <p>
            Zugelassen bei der Rechtsanwaltskammer M&uuml;nchen (Tal 33, 80331
            M&uuml;nchen)          
        </p>
        <p>
            Allgemeine &Ouml;ffnungszeiten: Mo - Fr von 9:00 bis 18:00 (Vorher
            anrufen)          
        </p>
        <p>
            In dringenden Notf&auml;llen k&ouml;nnen Sie uns jedoch 7 Tage / 24
            Stunden erreichen.          
        </p>
        <p>
            Steuernummer: 156/272/71198 &nbsp; / &nbsp; USt.IdentNr.:
            DE275670914          
        </p>
        <p>
            Weitere{" "}
            <a href="/informationen" target="_blank">
              Informationen
            </a>
            , insbesondere nach DL-InfoV finden Sie unter
            http://www.kanzlei-schweikert.de/informationen.          
        </p>
        <p>
            www.kanzlei-schweikert.de / www.kanzlei-oberbayern.de /
            www.alpinrecht.eu          
        </p>
      </div>
    </Layout>
  )
}
